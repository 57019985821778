import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

const useIsLanding = () => {
  const [isLanding, setIsLanding] = useState(false);
  const location = useLocation();
  const landingPaths = ["/solutions/", "/segments/", "/products/"];

  const checkReferrerIsLanding = () => {
    const referrer = document.referrer || "";
    return landingPaths.some((path) => referrer.includes(path));
  };

  useEffect(() => {
    const stateIsLanding = !!location?.state?.isLanding || checkReferrerIsLanding();
    setIsLanding(stateIsLanding);
  }, [location]);

  return isLanding;
};

export default useIsLanding;

import React from "react";
import { Box, Button, Slide, Stack, Typography, Link } from "@mui/material";
import { Trans } from "@lingui/macro";
import _ from "lodash";
import styled from "@emotion/styled";
import { useLocalization } from "gatsby-theme-i18n";
import { useLocation } from "@reach/router";
import ModalCookies from "../ModalCookies";
import { useCookiesHook } from "../../hooks/useCookiesHook";

// const Transition = React.forwardRef(
//   (props, ref) => <Slide ref={ref} {...props} direction="up" /> // timeout={1500}
// )

function ModalFooterCookies() {
  const { locale } = useLocalization();
  const { cookies, acceptAllCookies, rejectOptionalCookies } = useCookiesHook();
  const { pathname } = useLocation();
  const [showModal, setShowModal] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const hidden = ["cookies", "policy"].some((item) => pathname.includes(item));

  React.useEffect(() => {
    setShow(!hidden ? _.isEmpty(cookies) : false);
  }, [cookies]);

  return (
    <>
      {/* <Dialog
        open={show}
        sx={{
          ".MuiPaper-root": {
            position: "fixed",
            bottom: 0,
            margin: 0,
            width: "100%",
            maxWidth: "100%",
            borderRadius: "0px",
          },
        }}
        TransitionComponent={Transition}
      > */}
      <Slide in={show} direction="up">
        <Box
          sx={{
            bottom: 0,
            margin: 0,
            zIndex: 999,
            width: "100%",
            position: "fixed",
            borderRadius: "0px",
            backgroundColor: (theme) => theme.palette.colors.blue.dark,
            borderTop: (theme) => `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Box
            sx={{
              py: 3,
              px: { xs: 2 },
              margin: "0 auto",
              maxWidth: "960px",
              color: (theme) => theme.palette.colors.white.main,
            }}
          >
            <Typography variant="h3">
              <Trans>Cookies</Trans>
            </Typography>
            <StyledBox mt={1} mb={2}>
              <Typography color={(theme) => theme.palette.colors.white.main}>
                <Trans>
                  Allfunds Bank S.A.U. (Allfunds), data controller, uses its own
                  and third-party cookies to measure the volume of visits to the
                  website and user interactions to improve the client
                  experience. To this end, only navigation data or data linked
                  to the registered user will be obtained. If you accept all
                  cookies, you consent to their use and installation.
                  <br />
                  You can change the configuration here , choosing the cookies
                  you want to install in your device and remembering your
                  preferences. Click if you need additional information about
                </Trans>
                <Link href={`/${locale}/legal-advice/cookies-policy`} className="link" target="_blank">
                  <Trans>Allfunds Cookies Policy.</Trans>
                </Link>
              </Typography>
            </StyledBox>
            <Stack
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <LightButton
                variant="outlined"
                mr={2}
                onClick={() => {
                  rejectOptionalCookies();
                  setShow(false);
                }}
              >
                <Typography variant="info">
                  <Trans>Reject all cookies</Trans>
                </Typography>
              </LightButton>
              <LightButton
                variant="outlined"
                onClick={() => setShowModal(!showModal)}
              >
                <Typography variant="info">
                  <Trans>Configure cookies</Trans>
                </Typography>
              </LightButton>
              <DarkButton
                variant="outlined"
                mr={2}
                onClick={() => {
                  acceptAllCookies();
                  setShow(false);
                }}
              >
                <Typography variant="info">
                  <Trans>Accept all cookies</Trans>
                </Typography>
              </DarkButton>
            </Stack>
          </Box>
        </Box>
      </Slide>
      {/* </Dialog> */}
      {showModal && (
        <ModalCookies
          open
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

const StyledBox = styled(Box)`
  .link {
    margin-left: 5px;
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.secondary.main};
    textdecorationcolor: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const LightButton = styled(Button)`
  height: 36px;
    padding: 9.5px 12px!important;
  color: ${({ theme }) => theme.palette.colors.white.main};
  background-color: rgba(231, 33, 79, 0.2);
  border: ${({ theme }) => theme.palette.secondary.main} 1px solid;
  &:hover {
    background-color: ${({ theme }) => theme.palette.colors.red.dark};
    border-color: ${({ theme }) => theme.palette.colors.red.dark};
  }
`;

const DarkButton = styled(Button)`
  height: 36px;
    padding: 9.5px 12px!important;
  color: ${({ theme }) => theme.palette.colors.white.main};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border: ${({ theme }) => theme.palette.secondary.main} 1px solid;
  &:hover {
    background-color: ${({ theme }) => theme.palette.colors.red.dark};
    border-color: ${({ theme }) => theme.palette.colors.red.dark};
  }
`;

export default ModalFooterCookies;
